<template>
  <div :class="['homePage', { homePageMobile: mobileWidth }]">
    <exhibitor v-if="!mobileWidth" />
    <exhibitorMobile v-if="mobileWidth" />
  </div>
</template>

<script>
import api from "@/api";
import exhibitor from "./sourcing-inquiry-exhibitor";
import exhibitorMobile from "./sourcing-inquiry-exhibitor-mobile";
import { mapState } from "vuex";
const { _createSourcing } = api;
export default {
  computed: {
    ...mapState("user", ["token", "role"])
  },
  components: {
    exhibitor,
    exhibitorMobile
  },
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      inquireForm: {},
      inputNote: "input-note"
    };
  },
  // created:{
  //
  // },
  mounted() {},
  methods: {
    sendInquire() {
      this.mloading = true;
      _createSourcing(this.inquireForm)
        .then(() => {
          if (!this.role) this.showLinkTip();
          else this.$Message.success("Add Sourcing Success");

          this.showInquire = false;
          this.handleReset("form");
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.mloading = false;
        });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
}
@media (max-width: 1600px) and (min-width: 1400px) {
}
@media (max-width: 1400px) and (min-width: 1200px) {
}
@media (max-width: 1200px) {
}
.homePage {
  background: #f4f4f4;
  margin-top: 128px;
  height: 1250px;
}
.homePageMobile {
  background: #f4f4f4;
  margin-top: 10px;
  height: auto !important;
}
.sc-width {
  margin: 0 auto;
}
.router-transitions {
  opacity: 1;
}
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}
.register-form >>> .ivu-input {
  /*width:400px;*/
  height: 36px;
}
.register-form >>> .ivu-select-input {
  /*width:400px;*/
  height: 36px;
}
.register-form >>> .ivu-form-item-label {
  font-size: 18px;

  font-weight: 500;
  color: #292929;
  line-height: 18px;
}
.register-form >>> .input-note textarea {
  height: 100px;
}
body.app-loading .router-transitions {
  opacity: 1;
}
</style>
