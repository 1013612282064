<template>
  <div class="content">
    <div class="title font-black">Please enter your purchase requirement</div>
    <div class="body">
      <div class="form">
        <Form
          ref="form"
          :model="inquireForm"
          class="register-form"
          :label-width="200"
        >
          <Row :gutter="20">
            <i-col span="16">
              <FormItem
                label="Product Name"
                :rules="required"
                prop="product_name"
              >
                <Input v-model="inquireForm.product_name" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="8">
              <FormItem
                label="Quality Standard"
                :rules="required"
                prop="quality_standard"
              >
                <Input v-model="inquireForm.quality_standard" />
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="Spec" :rules="required" prop="spec">
                <Input v-model="inquireForm.spec" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="12">
              <FormItem label="COA Attached" prop="coa_attached">
                <div
                  v-if="!(fileCOA || urlCOA)"
                  class="upload"
                  @click="$refs.fileCOA.click()"
                >
                  <div class="upload-inner">
                    <input
                      @change="handleUploadCOA"
                      v-show="false"
                      type="file"
                      ref="fileCOA"
                    />
                    <p style="color:#F08328">+Add Attached</p>
                  </div>
                </div>
                <div class="upload" v-else>
                  <div v-if="uplodLoading">
                    Uploading...
                  </div>
                  <div v-else>
                    <div v-if="urlCOA">
                      <Alert type="success">File upload successful</Alert>
                      <Button @click="removeFileCOA">Choose Again</Button>
                    </div>
                  </div>
                </div>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="12">
              <FormItem label="Quantity" :rules="required" prop="quantity">
                <Input v-model="inquireForm.quantity" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="12">
              <FormItem
                label="Price Indication"
                :rules="required"
                prop="price_indication"
              >
                <Input v-model="inquireForm.price_indication" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="12">
              <FormItem label="FOB Basis" :rules="required" prop="packaging">
                <Input v-model="inquireForm.fob_basis" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="12">
              <FormItem label="Package" :rules="required" prop="packaging">
                <Input v-model="inquireForm.packaging" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="12">
              <FormItem label="Shipment" :rules="required" prop="shipment">
                <Input v-model="inquireForm.shipment" />
              </FormItem>
            </i-col>
          </Row>
          <!--           <Row :gutter="20">-->
          <!--             <i-col span="8">-->
          <!--               <FormItem-->
          <!--                       label="Payment Term"-->
          <!--                       :rules="required"-->
          <!--                       prop="payment_term"-->
          <!--               >-->
          <!--                 <DatePicker type="date" v-model="inquireForm.payment_term" format="yyyy-MM-dd" placeholder="Please select a date"></DatePicker>-->
          <!--               </FormItem>-->
          <!--             </i-col>-->
          <!--           </Row>-->
          <Row :gutter="20">
            <i-col span="12">
              <FormItem
                label="QC Documents/Facory Certificate"
                prop="coa_attached"
              >
                <div
                  v-if="!(fileQC || urlQC)"
                  class="upload"
                  @click="$refs.fileQC.click()"
                >
                  <div class="upload-inner">
                    <input
                      @change="handleUploadQC"
                      v-show="false"
                      type="file"
                      ref="fileQC"
                    />
                    <p style="color:#F08328">+Add Document/Add Attached</p>
                  </div>
                </div>
                <div class="upload" v-else>
                  <div v-if="uplodLoading">
                    Uploading...
                  </div>
                  <div v-else>
                    <div v-if="urlQC">
                      <Alert type="success">File upload successful</Alert>
                      <Button @click="removeFileQC">Choose Again</Button>
                    </div>
                  </div>
                </div>
              </FormItem>
            </i-col>
          </Row>
          <!--           <Row :gutter="20">-->
          <!--             <i-col span="16">-->
          <!--               <FormItem-->
          <!--                       label="Attached"-->
          <!--                       :rules="required"-->
          <!--                       prop="attached"-->
          <!--               >-->
          <!--                 <Input v-model="inquireForm.attached" />-->
          <!--               </FormItem>-->
          <!--             </i-col>-->
          <!--           </Row>-->
          <Row :gutter="20">
            <i-col span="16">
              <FormItem label="Note" prop="validity" :class="inputNote">
                <Input
                  v-model="inquireForm.note"
                  type="textarea"
                  placeholder=""
                  :rows="4"
                  :class="inputNote"
                  style="width: 100%;height:100px"
                />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="12">
              <FormItem
                label="Expire Date"
                :rules="required"
                prop="expire_date"
              >
                <DatePicker
                  type="date"
                  v-model="inquireForm.expire_date"
                  format="yyyy-MM-dd"
                  placeholder="Please select a date"
                ></DatePicker>
              </FormItem>
            </i-col>
          </Row>
        </Form>
      </div>
      <div
        v-if="!mloading"
        class="btn font-medium"
        @click="handleSubmit('form')"
      >
        Submit
      </div>
      <div v-else class="btn font-medium">
        Sending...
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { parseTime } from "@/utils/helper";

const { _createSourcing } = api;
import S3 from "aws-s3";
const uploader = new S3({
  bucketName: "connexima",
  dirName: "documents",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});
export default {
  name: "sourcing-inquiry-exhibitor",
  computed: {},
  components: {},
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      inquireForm: {},
      inputNote: "input-note",
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      fileCOA: null,
      fileQC: null,
      urlCOA: "",
      urlQC: "",
      uplodLoading: false,
      mloading: false,
      create: {
        name: "",
        type: "",
        extraType: ""
      }
    };
  },
  // created:{
  //
  // },
  mounted() {
    parseTime;
    this.inquireForm.product_name = this.$route.query.first;
    this.inquireForm.quality_standard = this.$route.query.second;
    this.inquireForm.spec = this.$route.query.third;
    // this.inquireForm.application = this.$route.query.d
  },
  methods: {
    removeFileCOA() {
      this.fileCOA = null;
      this.urlCOA = "";
    },
    removeFileQC() {
      this.fileQC = null;
      this.urlQC = "";
    },
    sendInquire() {
      this.mloading = true;
      if ("" !== this.urlCOA) {
        this.inquireForm.coa = this.urlCOA;
      }
      if ("" !== this.urlQC) {
        this.inquireForm.qc = this.urlQC;
      }
      // this.inquireForm.payment_term = parseTime(this.inquireForm.payment_term,'{y}/{m}/{d} {h}:{i}:{s}')
      _createSourcing(this.inquireForm)
        .then(() => {
          // if (!this.role) this.showLinkTip();
          this.$Message.success("Add Sourcing Success");

          // this.showInquire = false;
          this.handleReset("form");
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.mloading = false;
        });
    },
    handleSubmit(name) {
      console.log(name);
      this.$refs[name].validate(valid => {
        if (valid) {
          this.sendInquire();
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleUploadCOA(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 20MB");
        } else {
          this.fileCOA = file;
          this.uploadCOA();
        }
      }
    },
    handleUploadQC(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 20MB");
        } else {
          this.fileQC = file;
          this.uploadQC();
        }
      }
    },
    uploadCOA() {
      this.uplodLoading = true;
      uploader
        .uploadFile(this.fileCOA)
        .then(data => {
          this.urlCOA = data.location;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodLoading = false;
          // this.$refs.create.validateField("urlCOA");
        });
    },
    uploadQC() {
      this.uplodLoading = true;
      uploader
        .uploadFile(this.fileQC)
        .then(data => {
          this.urlQC = data.location;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodLoading = false;
          // this.$refs.create.validateField("urlQC");
        });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
}
@media (max-width: 1600px) and (min-width: 1400px) {
  .content {
    width: 1050px !important;
  }
  .form .register-form {
    left: 120px !important;
  }
  .btn {
    left: 375px !important;
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .content {
    width: 950px !important;
  }
  .form .register-form {
    left: 110px !important;
  }
  .btn {
    left: 330px !important;
  }
}
@media (max-width: 1200px) {
  .content {
    width: 900px !important;
  }
  .form .register-form {
    left: 110px !important;
  }
  .btn {
    left: 320px !important;
  }
}
.homePage {
  background: #f4f4f4;
  margin-top: 128px;
}
.sc-width {
  margin: 0 auto;
}
.content {
  background: #ffffff;
  box-shadow: 0px 0px 0px 6px rgba(239, 132, 45, 0.3);
  border-radius: 5px;
  width: 1200px;
  height: 1130px;
  margin: 0 auto 130px auto;
  position: relative;
  top: 56px;
}
.title {
  font-size: 24px;

  font-weight: 700;
  color: #ef7f21;
  line-height: 18px;
  position: relative;
  top: 64px;
  left: 96px;
}
.form {
  margin: 0 auto;
  .item {
    display: flex;
    justify-content: center;
  }
  .register-form {
    position: relative;
    left: 150px;
    top: 154px;
  }
}
.btn {
  width: 290px;
  height: 40px;
  background: linear-gradient(90deg, #f5a210, #ef8126);
  border-radius: 5px;
  font-size: 20px;

  font-weight: 500;
  color: #fefefe;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
  position: relative;
  top: 200px;
  left: 400px;
  cursor: pointer;
}
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}
.register-form >>> .ivu-input {
  /*width:400px;*/
  height: 36px;
}
.register-form >>> .ivu-select-input {
  /*width:400px;*/
  height: 36px;
}
.register-form >>> .ivu-form-item-label {
  font-size: 18px;

  font-weight: 500;
  color: #292929;
  line-height: 18px;
}
.register-form >>> .input-note textarea {
  height: 100px;
}
.upload {
  width: 100%;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 2px;
  cursor: pointer;
}

.upload-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
